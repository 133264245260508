module.exports = {
  siteTitle: 'Martin Bax', // <title>
  manifestName: 'Martin Bax',
  manifestShortName: 'Martin Bax', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#343a40',
  manifestThemeColor: '#343a40',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon-2.png',
  heading: 'Martin Bax',
  subHeading: 'Professional interpreter, translator and editor',
  // social
  socialLinks: [

    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'unspellablenick@gmail.com',
    },
  ],
  phone: '+82 10 4993 2406',
  address: 'Seoul, South Korea',
};
